<template>
  <section>
    <dialog-component
      :visible.sync="getLinkModal"
      top="30vh"
      title="Get Link"
    
      :containerMaxHeight="'75%'"
      :containerMinHeight="'30%'"
      :containerMaxWidth="getIsMobile?'100%':'100%'"
      @before-close="closeGetLink"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-draggable
    >
      <div class="link-main m-tb-2">
        <div class="link-head">
          <el-row>
            <el-col :span="6">
              <p class="link-title mb-1">{{ documentName }}</p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <p class="link-sub">Choose how to distribute your template.</p>
            </el-col>
            <!-- <el-col :span="6" :offset="6">
              <el-checkbox v-model="is_published" class="float-right">Publish status</el-checkbox>
            </el-col> -->
          </el-row>
        </div>
        <el-divider></el-divider>
        <el-row :gutter="30">
          <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
            <el-card>
            <div class="link-col">
              <p class="link-cap">Embed code</p>
              <span class="cap-sub" :style="getIsMobile ? '' : 'white-space: nowrap;'">
                To embed your form on your own site, copy the code below.</span
              >
              <div style="display: flex" class="mt-1">
                <el-input
                  size="mini"
                  v-model="embedCode"
                  ref="getCode"
                  v-on:focus="$event.target.select()"
                ></el-input>
                <el-button
                  type="danger"
                  icon="el-icon-copy-document"
                  class="copy-btn ml-1"
                  size="mini"
                  @click="copyCodeToClipboard"
                  >Copy Code</el-button
                >
              </div>
            </div>
          </el-card>
          </el-col>
          <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
            <el-card class="mr-1"> 
            <div class="link-col">
              <p class="link-cap">Web link</p>
              <span class="cap-sub"
                >Send a direct link or paste the link in your website.</span
              >
              <div style="display: flex" class="mt-1">
                <el-input
                  size="mini"
                  v-model="getLink"
                  ref="getlink"
                  v-on:focus="$event.target.select()"
                ></el-input>
                <el-button
                  type="danger"
                  icon="el-icon-copy-document"
                  class="copy-btn ml-1"
                  size="mini"
                  @click="copyLinkToClipboard"
                  >Copy Link</el-button
                >
              </div>
            </div>
            </el-card>
          </el-col>
        </el-row>
        <div class="link-alert mt-1">
          <div>
            <el-checkbox
              class="float-left"
              :disabled="this.is_published"
              v-model="access_to_all"
              >Allow new users to fill</el-checkbox
            >
          </div>
          <div>
            <el-checkbox
              class="float-left"
              :disabled="this.is_published"
              v-model="secure_access"
              >Secure access</el-checkbox
            >
          </div>
          <!-- <p class="alert-text">
             This link can be accessed by
          </p>
          <el-select
            v-model="access_to_all"
            placeholder="Select"
            size="mini"
            class="ml-1"
            style="width: 170px"
            :disabled="this.is_published"
          >
            <el-option
              v-for="item in accessing_data"
              :key="item.key"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
        </div>
      </div>
      <el-row :gutter="30" class="mt-1">
        <el-col :span="3">
          <!-- <el-checkbox
            class="float-left"
            :disabled="this.is_published"
            v-model="update_contacts"
            >Update contacts with recipient's entered data</el-checkbox
          > -->
        </el-col>
        <el-col :span="7" :offset="14" class="link-footer">
          
          <!-- <el-switch
            style="display: block; margin-top:2px;"
            v-model="is_published"
            class="ml-1"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="Publish"
            inactive-text="Unpublish"
          >
          </el-switch> -->
        </el-col>
      </el-row>
      <span slot="footer" >
        <el-button @click="closeGetLink" class="link-cancel">Close</el-button>
          <el-button
            type="primary"
            class="link-publish"
            @click="publishTheTemplate"
            v-if="!is_published"
            :loading="btn_loading"
            >Publish</el-button
          >
          <el-button
            type="primary"
            class="link-publish"
            @click="unpublishTheTemplate"
            v-if="is_published"
            :loading="btn_loading"
            >Unpublish</el-button
          >
      </span>
    </dialog-component>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import appConfig from "@/config/app";
export default {
  name: "templates-Configure-GetLink-Template",
  props: ["configurableDocument", "linkModal"],
  data() {
    return {
      accessing_data: [
        {
          key: "ALL",
          label: "Allow new user",
          value: true,
        },
        {
          key: "CONTACT_TYPE",
          label: "Specified Recipients",
          value: false,
        },
      ],
      embedCode: "",
      getLink: "",
      getLinkModal: true,
      documentName: "",
      is_published: false,
      secure_access: false,
      update_contacts: false,
      access_to_all: false,
      btn_loading: false,
    };
  },
  computed: {
    ...mapGetters("configurableDocuments", [
      "getConfigurableDocumentDataById",
      "getConfigurableDocumentUpdateStatus",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
  },
  async mounted() {
    await this.setData();
  },
  methods: {
    setData() {
      this.documentName = this.configurableDocument["name"];
      this.is_published = this.configurableDocument["is_published"]
        ? this.configurableDocument["is_published"]
        : false;
      this.secure_access = this.configurableDocument["secure_access"]
        ? this.configurableDocument["secure_access"]
        : false;
      this.access_to_all = this.configurableDocument["access_to_all"]
        ? this.configurableDocument["access_to_all"]
        : false;
      this.update_contacts = this.configurableDocument["update_contacts"]
        ? this.configurableDocument["update_contacts"]
        : false;
      this.generateLinkForTemplate();
      this.generateCodeForTemplate();
    },
    generateLinkForTemplate() {
      let url = appConfig.APP_URL;
      // if (process.env.VUE_APP_STAGE == "DEV") {
      //   url = "https://dev.esigns.io";
      // } else if (process.env.VUE_APP_STAGE == "APP") {
      //   url = "https://app.esigns.io";
      // }
      // url = "http://localhost:8080";
      let company_document_id = this.configurableDocument.company_document_id;
      let configurable_document_id = this.configurableDocument._id;
      url += `/company-documents/${company_document_id}/configure/template/${configurable_document_id}/preview`;
      this.getLink = url;
    },
    generateCodeForTemplate() {
      let company_document_id = this.configurableDocument.company_document_id;
      let configurable_document_id = this.configurableDocument._id;
      let code = `<div id="${configurable_document_id}"></div>`;
      let url = appConfig.APP_URL;
      // if (process.env.VUE_APP_STAGE == "DEV") {
      //   url = "https://dev.esigns.io";
      // } else if (process.env.VUE_APP_STAGE == "APP") {
      //   url = "https://app.esigns.io";
      // }

      // url = "http://localhost:8080";
      url += `/company-documents/${company_document_id}/configure/template/${configurable_document_id}/preview`;
      // code += '<script type="text/javascript">';
      code += `<script type="text/javascript"> (function () { var config = { nodeId: '${configurable_document_id}', width: '100%', height: '700px', url: '${url}', events: { loaded: function () {}, started: function (data) {}, completed: function (data) {}, exception: function (data) {} }, data: {}, }; var iframe = document.createElement('iframe'); iframe.frameBorder = 0; iframe.src = config.url; if (config.nodeId) { var node = document.getElementById(config.nodeId); node.style.height = config.height; node.style.width = config.width; iframe.height = '100%'; iframe.width = '100%'; node.append(iframe); } else { iframe.height = config.height; iframe.width = config.width; document.body.append(iframe); }})();`;

      code += "</sc";
      code += "ript>";
      this.embedCode = code;
    },
    async publishTheTemplate() {
      try {
        this.btn_loading = true;
        this.is_published = true;
        let params = {
          id: this.configurableDocument._id,
          company_document_id: this.configurableDocument.company_document_id,
          is_published: true,
          access_to_all: this.access_to_all,
          secure_access: this.secure_access,
          update_contacts: this.update_contacts,
        };
        await this.$store.dispatch(
          "configurableDocuments/updateConfigurableDocument",
          params
        );
        if (this.getConfigurableDocumentUpdateStatus) {
          this.btn_loading = false;
          this.$notify.success({
            title: "Success",
            message: "Template published successfully",
          });
          // this.closeGetLink();
        } else {
          this.btn_loading = false;
          this.$notify.error({
            title: "Error",
            message: "Error in publishing the template",
          });
        }
      } catch (err) {
        console.log("publishTheTemplate",err);
      }
    },
    refreshDocument() {
      window.location.reload();
    },
    async unpublishTheTemplate() {
      try {
        this.btn_loading = true;
        this.is_published = false;
        let params = {
          id: this.configurableDocument._id,
          company_document_id: this.configurableDocument.company_document_id,
          is_published: false,
        };
        await this.$store.dispatch(
          "configurableDocuments/updateConfigurableDocument",
          params
        );
        if (this.getConfigurableDocumentUpdateStatus) {
          this.btn_loading = false;
          this.$notify.success({
            title: "Success",
            message: "Unpublished successfully",
          });
          // this.closeGetLink();
        } else {
          this.btn_loading = false;
          this.$notify.error({
            title: "Error",
            message: "Error in Unpublishing the template",
          });
        }
      } catch (err) {
        console.log("unpublishTheTemplate",err);
      }
    },

    closeGetLink() {
      this.$emit("close", {});
    },
    copyCodeToClipboard() {
      this.$refs.getCode.focus();
      document.execCommand("copy");
    },
    copyLinkToClipboard() {
      this.$refs.getlink.focus();
      document.execCommand("copy");
    },
  },
};
</script>
<style scoped>
.link-main .link-col {
    background: #fff;
    border: 1px solid hsla(0, 0%, 88.2%, .4);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 11px 30px rgba(234, 236, 241, .58);
    box-shadow: 0 11px 30px rgba(234, 236, 241, .58);
    border-radius: 2px;
    padding: 10px;
    width: 103%;
}
.link-main .link-alert {
    background: #edf5fa;
    border-left: .1px solid #409eff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    margin: auto;
}
.link-footer{
  @media (min-width:300px){
    display:flex;
    position:relative;
    right:50px;
  }
}
</style>
